(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/endpoints/marketplace.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/endpoints/marketplace.js');
"use strict";


const getMarketplaceEndpoints = builder => ({
  getMarketplaceWagerdetails: builder.query({
    query: _ref => {
      let {
        groupId,
        wagerId
      } = _ref;
      return {
        url: "/marketplace/1/wagerdetails/".concat(groupId),
        params: {
          wagerId
        }
      };
    },
    transformResponse: res => res.containerWithDraw
  }),
  getMarketplaceGroups: builder.query({
    query: () => ({
      url: '/marketplace/3/groups',
      params: {
        count: 999,
        include: 'groups.groupId,groups.name'
      }
    }),
    transformResponse: res => res.groups || []
  })
});
setGlobal('svs.components.tipsen.store.endpoints.getMarketplaceEndpoints', getMarketplaceEndpoints);

 })(window);