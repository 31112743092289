(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/middlewares/dialog-mw.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/middlewares/dialog-mw.js');
"use strict";

const {
  isAnyOf
} = RTK;
const {
  getLogger
} = svs.core.log;
const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  getWager,
  playerForecastError
} = svs.components.tipsen.store.helpers.dialog;
const {
  matchRejected: getPlayerWagerRejected
} = tipsenApi.endpoints.getPlayerWager;
const {
  matchRejected: playerForecastRejected
} = tipsenApi.endpoints.getPlayerForecast;
const {
  matchRejected: drawForecastRejected
} = tipsenApi.endpoints.getDrawForecastFetch;
const {
  playerForecastErrorMessage
} = svs.components.tipsen.store.helpers.constants;
const logger = getLogger('tipsen:store:mw:dialog');
const isPlayerWagerAction = isAnyOf(getPlayerWagerRejected);
const isForecastRejected = isAnyOf(playerForecastRejected, drawForecastRejected);
const dialogMw = _ref => {
  let {
    getState
  } = _ref;
  return next => action => {
    const state = getState();
    try {
      var _action$payload, _action$payload2, _action$payload3, _action$payload4, _action$payload5, _method;
      let method;
      if (isPlayerWagerAction(action) && ((_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.status) >= 400 && ((_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.status) < 600) {
        method = getWager.getWagerFailure;
      }
      if (isForecastRejected(action) && (((_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.message) === playerForecastErrorMessage || ((_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.status) >= 400 && ((_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.status) < 600)) {
        logger.warn("Feteching forecast error with args: ".concat(JSON.stringify(action.meta.arg.originalArgs)));
        method = playerForecastError.getPlayerForecastError;
      }
      (_method = method) === null || _method === void 0 || (_method = _method(state, action)) === null || _method === void 0 || _method.open();
    } catch (err) {
      var _action$error, _action$meta$arg$orig;
      const stack = err.stack ? " ".concat(err.stack) : (_action$error = action.error) !== null && _action$error !== void 0 && _action$error.stack ? action.error.stack : '';
      const args = (_action$meta$arg$orig = action.meta.arg.originalArgs) !== null && _action$meta$arg$orig !== void 0 ? _action$meta$arg$orig : action.meta.arg.data;
      logger.warn("Unable to show dialog for action: ".concat(action.type, ", args: ").concat(JSON.stringify(args)).concat(stack));
    } finally {
      next(action);
    }
  };
};
setGlobal('svs.components.tipsen.store.middlewares.dialogMw', dialogMw);

 })(window);