(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/middlewares/tracking-mw.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/middlewares/tracking-mw.js');
"use strict";

const {
  isAnyOf
} = RTK;
const {
  toggleCorrectsTracking
} = svs.components.tipsen.store.helpers.tracking;
const {
  toggleSubscriptionOption
} = svs.components.tipsen.store.reducers.playerWagerListSubscriptionSlice.actions;
const {
  wagerListForecastSubscriptions: wagerListForecastSubscriptionsActions
} = svs.components.tipsen.store.reducers.playerWagerListSubscriptionSlice.actions;
const {
  wagerListForecastSubscriptions
} = svs.components.tipsen.store.reducers.playerWagerListSubscriptionSlice.selectors;
const {
  getLogger
} = svs.core.log;
const logger = getLogger('tipsen:store:mw:tracking');
const isInterestingToggleAllAction = isAnyOf(toggleSubscriptionOption);
const isInterestingToggleIndividualAction = isAnyOf(wagerListForecastSubscriptionsActions.toggleSubscriptionForWager);
const trackingMw = _ref => {
  let {
    getState
  } = _ref;
  return next => action => {
    try {
      if (isInterestingToggleAllAction(action)) {
        const {
          productId,
          isToggleOn
        } = action.payload;
        toggleCorrectsTracking({
          type: 'corrects_coupon',
          productId,
          isToggleOn
        });
      }
      if (isInterestingToggleIndividualAction(action)) {
        var _wagerListForecastSub, _wagerListForecastSub2;
        const {
          productId,
          wagerId
        } = action.payload;
        const state = getState();
        const isSubscribed = (_wagerListForecastSub = (_wagerListForecastSub2 = wagerListForecastSubscriptions.selectById(state, wagerId)) === null || _wagerListForecastSub2 === void 0 ? void 0 : _wagerListForecastSub2.isSubscribed) !== null && _wagerListForecastSub !== void 0 ? _wagerListForecastSub : false;
        toggleCorrectsTracking({
          type: 'corrects_coupon_individual',
          productId,
          isToggleOn: !isSubscribed
        });
      }
    } catch (error) {
      logger.warn("Something went wrong ".concat(JSON.stringify(action), ". With args: ").concat(JSON.stringify(action.meta.arg.originalArgs)));
    } finally {
      next(action);
    }
  };
};
setGlobal('svs.components.tipsen.store.middlewares.trackingMw', trackingMw);

 })(window);