(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/middlewares/action-enhancer-mw.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/middlewares/action-enhancer-mw.js');
"use strict";

const {
  isAsyncThunkAction,
  isAnyOf
} = RTK;
const {
  tipsenApi
} = svs.components.tipsen.store;
const EndpointsOverridingLogLevel = ['examinePlayerWager', 'getMarketplaceWagerdetails', 'getSportkryssResult'];
const getPlayerWagersAction = tipsenApi.endpoints.getPlayerWagers.matchFulfilled;
const isInterestingWagerAction = isAnyOf(getPlayerWagersAction);
const actionEnhancerMw = _ref => {
  let {
    getState
  } = _ref;
  return next => action => {
    try {
      var _action$meta;
      if (EndpointsOverridingLogLevel.includes((_action$meta = action.meta) === null || _action$meta === void 0 || (_action$meta = _action$meta.arg) === null || _action$meta === void 0 ? void 0 : _action$meta.endpointName)) {
        action.meta.logLevelOverride = 'info';
      }
      if (!action.meta) {
        action.meta = {};
      }
      action.meta.enhanchedData = {};
      if (isAsyncThunkAction(action)) {
        action.meta.enhanchedData._svsTimestamp = Date.now();
      }
      if (isInterestingWagerAction(action)) {
        const {
          status,
          pagingSortKey,
          productId
        } = action.meta.arg.originalArgs;
        const state = getState();
        if (!pagingSortKey) {
          var _state$PlayerWager$Wa, _toggleState$productI;
          const toggleState = (_state$PlayerWager$Wa = state.PlayerWager.WagerListSettings) === null || _state$PlayerWager$Wa === void 0 || (_state$PlayerWager$Wa = _state$PlayerWager$Wa.WagerListSubscriptionSettings) === null || _state$PlayerWager$Wa === void 0 ? void 0 : _state$PlayerWager$Wa.correctsToggle;
          const isProductToggled = typeof toggleState === 'object' && ((_toggleState$productI = toggleState[productId]) === null || _toggleState$productI === void 0 ? void 0 : _toggleState$productI[status]) || false;
          action.meta.enhanchedData.isProductToggled = isProductToggled;
        }
      }
    } finally {
      next(action);
    }
  };
};
setGlobal('svs.components.tipsen.store.middlewares.actionEnhancerMw', actionEnhancerMw);

 })(window);