(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/make-store.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/make-store.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  combineReducers,
  createNextState,
  configureStore
} = RTK;
const {
  setupListeners
} = RTKQ;
const {
  getLogger
} = svs.core.log;
const {
  getReducers,
  definitions,
  createCoupons,
  actions: engineActions,
  constants: engineConstants
} = svs.components.tipsen.engine;
const {
  moduleReducer,
  couponSettingsReducer
} = svs.components.tipsen.store.reducers;
const {
  reducer: drawsReducer
} = svs.components.sport.drawsRedux.slice;
const {
  reducer: fundsReducer
} = svs.components.sport.fundsRedux.slice;
const {
  slice: playerWagerSlice
} = svs.components.tipsen.store.reducers.playerWager;
const {
  reducer: resultModifierReducer
} = svs.components.tipsen.store.reducers.resultModifier;
const {
  slice: betslipSlice
} = svs.components.tipsen.store.reducers.betslipSlice;
const {
  makePlayerWagerListReducer
} = svs.components.tipsen.store.reducers.playerWagerListSubscriptionSlice;
const {
  walletReducer
} = svs.components.tipsen.wallet;
const {
  loadingStateReducer,
  loadingStateMiddleware
} = svs.components.tipsen.loadingState;
const {
  betEventReducer,
  sportEventReducer,
  leagueReducer,
  sportReducer,
  participantReducer,
  countryReducer,
  eventTypeStatisticReducer,
  generateParticipantsMw,
  actions: matchInfoActions
} = svs.components.sportinfo.matchInfoRedux;
const {
  gameAnalysesReducer
} = svs.components.sportinfo.gameAnalysesRedux;
const {
  articleReducer
} = svs.components.tipsen.article;
const {
  newsTTReducer
} = svs.components.sportinfo.newsTT;
const {
  actionEnhancerMw,
  kibanaMw,
  dialogMw,
  trackingMw,
  makeLocalStorageMiddleware
} = svs.components.tipsen.store.middlewares;
const {
  createReducerManager,
  createMiddlewareManager,
  tipsenApi,
  makeDrawsApi
} = svs.components.tipsen.store;
const {
  reducer: competitionReducer
} = svs.components.marketplaceCompetition.services.reducers;
const {
  competitionMiddlewares
} = svs.components.marketplaceCompetition.services.middlewares;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const blacklistedCouponValues = {
  selectedSystem: 0
};
const qs = new URLSearchParams(location.search);
const isExtendedReduxDevTools = typeof qs.get('extendedReduxDevTools') === 'string';
qs.delete('extendedReduxDevTools');
if (isExtendedReduxDevTools) {
  window.history.replaceState({}, null, "?".concat(qs.toString()));
}
const defaultAnalyseState = {
  showAll: false,
  showAnalyseForecast: false,
  hideAll: true
};
const makeStore = _ref => {
  var _cachedData$Coupons, _cachedData$CouponSet, _cachedData$PlayerWag, _clientStoredCouponSe, _clientStoredCouponSe2, _clientStoredCouponSe3, _clientStoredCouponSe4, _clientStoredCouponSe5, _clientStoredPlayerWa;
  let {
    preloadedState,
    lsKey
  } = _ref;
  const localStorageMw = makeLocalStorageMiddleware(lsKey, (persistedData, getState) => {
    var _PlayerWager$WagerLis;
    const lsLogger = getLogger('tipsen:store:mw:ls');
    const {
      Coupons,
      CouponSettings,
      PlayerWager
    } = persistedData || {};
    const state = getState();
    const {
      Coupons: newCoupons,
      CouponSettings: newCouponSettings,
      PlayerWager: {
        WagerListSettings: {
          WagerListSubscriptionSettings: {
            correctsToggle: newCorrectsToggle
          }
        }
      }
    } = state || {};
    const baseState = {
      Coupons: Coupons || {},
      CouponSettings: CouponSettings || {},
      PlayerWager: {
        WagerListSettings: {
          WagerListSubscriptionSettings: {
            correctsToggle: (PlayerWager === null || PlayerWager === void 0 || (_PlayerWager$WagerLis = PlayerWager.WagerListSettings) === null || _PlayerWager$WagerLis === void 0 || (_PlayerWager$WagerLis = _PlayerWager$WagerLis.WagerListForecastSubscriptions) === null || _PlayerWager$WagerLis === void 0 ? void 0 : _PlayerWager$WagerLis.correctsToggle) || {}
          }
        }
      }
    };
    const persistData = createNextState(baseState, draftState => {
      var _draftState$PlayerWag;
      newCorrectsToggle && Object.assign((_draftState$PlayerWag = draftState.PlayerWager) === null || _draftState$PlayerWag === void 0 || (_draftState$PlayerWag = _draftState$PlayerWag.WagerListSettings) === null || _draftState$PlayerWag === void 0 || (_draftState$PlayerWag = _draftState$PlayerWag.WagerListSubscriptionSettings) === null || _draftState$PlayerWag === void 0 ? void 0 : _draftState$PlayerWag.correctsToggle, newCorrectsToggle);
      newCoupons && Object.assign(draftState.Coupons, newCoupons);
      newCouponSettings && Object.assign(draftState.CouponSettings, newCouponSettings);

      for (const [key, value] of Object.entries(draftState.Coupons)) {
        if (value.isExternal) {
          draftState.Coupons[key].boards = [];
        }
        if (!state.Coupons[key].isModified) {
          delete draftState.Coupons[key];
        }
      }
    });
    lsLogger.debug(persistData);
    return persistData;
  });
  const cachedData = localStorageMw.getCachedData();
  const clientStoredCoupons = (_cachedData$Coupons = cachedData === null || cachedData === void 0 ? void 0 : cachedData.Coupons) !== null && _cachedData$Coupons !== void 0 ? _cachedData$Coupons : {};
  const clientStoredCouponSettings = (_cachedData$CouponSet = cachedData === null || cachedData === void 0 ? void 0 : cachedData.CouponSettings) !== null && _cachedData$CouponSet !== void 0 ? _cachedData$CouponSet : {};
  const clientStoredPlayerWagerSettings = (_cachedData$PlayerWag = cachedData === null || cachedData === void 0 ? void 0 : cachedData.PlayerWager) !== null && _cachedData$PlayerWag !== void 0 ? _cachedData$PlayerWag : {};
  const preloadedCoupons = {};
  const engineDefinition = definitions[preloadedState.Module.engine];
  const comphandler = competitionHandler();
  const competitions = comphandler.getActiveCompetition(definitions[preloadedState.Module.engine].drawProductIds[0]);
  const playerCompetitionDetails = comphandler.getPlayerCompetitions();
  if (preloadedState.Draws) {
    for (const couponId of Object.keys(clientStoredCoupons)) {
      const drawId = couponId.slice(0, couponId.lastIndexOf('_'));
      const productId = Number(drawId.slice(0, drawId.indexOf('_')));

      if (!engineDefinition.drawProductIds.includes(productId)) {
        continue;
      }

      if (!preloadedState.Draws.entities[drawId]) {
        delete clientStoredCoupons[couponId];
        continue;
      }

      for (const [blacklistedCouponKey, defaultValue] of Object.entries(blacklistedCouponValues)) {
        clientStoredCoupons[couponId][blacklistedCouponKey] = defaultValue;
      }
    }
    Object.assign(preloadedCoupons, createCoupons({
      draws: preloadedState.Draws.entities,
      engineDefinition
    }));
  }
  Object.assign(preloadedCoupons, clientStoredCoupons);
  const defaultOutcomeInfoChoises = {
    display: engineConstants.TipsinfoTypes.OutcomeLabel,
    displayResult: engineConstants.TipsinfoTypes.OutcomeLabel,
    displayMyBets: engineConstants.TipsinfoTypes.OutcomeLabel
  };
  const clientStoredOutcomeInfoChoises = (_clientStoredCouponSe = clientStoredCouponSettings === null || clientStoredCouponSettings === void 0 ? void 0 : clientStoredCouponSettings.OutcomeInfoChoises) !== null && _clientStoredCouponSe !== void 0 ? _clientStoredCouponSe : {};
  const preloadedOutcomeInfoChoises = _objectSpread(_objectSpread({}, defaultOutcomeInfoChoises), clientStoredOutcomeInfoChoises);
  const defaultMinistatInfo = {
    show: false,
    showResult: false,
    showMyBets: false
  };
  const clientStoredMinistatInfo = (_clientStoredCouponSe2 = clientStoredCouponSettings === null || clientStoredCouponSettings === void 0 ? void 0 : clientStoredCouponSettings.MinistatInfo) !== null && _clientStoredCouponSe2 !== void 0 ? _clientStoredCouponSe2 : {};
  const preloadedMinistatInfo = _objectSpread(_objectSpread({}, defaultMinistatInfo), clientStoredMinistatInfo);
  const clientStoredMatchAnalyses = (_clientStoredCouponSe3 = clientStoredCouponSettings === null || clientStoredCouponSettings === void 0 ? void 0 : clientStoredCouponSettings.MatchAnalyses) !== null && _clientStoredCouponSe3 !== void 0 ? _clientStoredCouponSe3 : {};
  const defaultMatchAnalyses = {
    bet: defaultAnalyseState,
    result: defaultAnalyseState,
    myBets: defaultAnalyseState
  };
  const preloadedMatchAnalyses = _objectSpread(_objectSpread({}, defaultMatchAnalyses), clientStoredMatchAnalyses);

  const defaultTipsinfo = {
    [engineDefinition.moduleEngine]: {
      odds: {
        state: true,
        stateResult: false,
        stateMyBets: false
      },
      svenskaFolket: {
        state: true,
        stateResult: false,
        stateMyBets: false
      },
      tioTidningar: {
        state: false,
        stateResult: false,
        stateMyBets: false
      },
      matchStart: {
        state: false,
        stateResult: false,
        stateMyBets: false
      },
      startOdds: {
        state: false,
        stateResult: false,
        stateMyBets: false
      },
      favourites: {
        state: false,
        stateResult: false,
        stateMyBets: false
      },
      mutuals: {
        state: false,
        stateResult: false,
        stateMyBets: false
      },
      overUnder: {
        state: false,
        stateResult: false,
        stateMyBets: false
      },
      [engineConstants.TipsinfoTypes.OutcomeLabel]: preloadedOutcomeInfoChoises,
      [engineConstants.TipsinfoTypes.Ministat]: preloadedMinistatInfo,
      [engineConstants.TipsinfoTypes.Analys]: preloadedMatchAnalyses
    }
  };
  const clientStoredTipsinfo = (_clientStoredCouponSe4 = clientStoredCouponSettings === null || clientStoredCouponSettings === void 0 ? void 0 : clientStoredCouponSettings.Tipsinfo) !== null && _clientStoredCouponSe4 !== void 0 ? _clientStoredCouponSe4 : {};
  const preloadedTipsinfo = _objectSpread(_objectSpread({}, defaultTipsinfo), clientStoredTipsinfo);
  const defaultCompressedCoupon = {
    [engineDefinition.moduleEngine]: {
      show: engineDefinition.couponSettings.includes(engineConstants.coupon.Compressed),
      showResult: false,
      showMyBets: false
    }
  };
  const clientStoredCompressedCoupon = (_clientStoredCouponSe5 = clientStoredCouponSettings === null || clientStoredCouponSettings === void 0 ? void 0 : clientStoredCouponSettings.CompressedCoupon) !== null && _clientStoredCouponSe5 !== void 0 ? _clientStoredCouponSe5 : {};
  const preloadedCompressedCoupon = _objectSpread(_objectSpread({}, defaultCompressedCoupon), clientStoredCompressedCoupon);
  const baseReducers = {
    [tipsenApi.reducerPath]: tipsenApi.reducer,
    Articles: articleReducer,
    BetEvents: betEventReducer,
    Betslip: betslipSlice,
    Competition: competitionReducer(competitions, playerCompetitionDetails),
    Countries: countryReducer,
    CouponSettings: couponSettingsReducer,
    Draws: drawsReducer,
    EventTypeStatistic: eventTypeStatisticReducer,
    Funds: fundsReducer,
    gameAnalyses: gameAnalysesReducer,
    Leagues: leagueReducer,
    loadingState: loadingStateReducer,
    Module: moduleReducer,
    newsTT: newsTTReducer,
    Participants: participantReducer,
    PlayerWager: combineReducers({
      DrawForecastSubscriptions: playerWagerSlice,
      WagerListSettings: makePlayerWagerListReducer((clientStoredPlayerWagerSettings === null || clientStoredPlayerWagerSettings === void 0 || (_clientStoredPlayerWa = clientStoredPlayerWagerSettings.WagerListSettings) === null || _clientStoredPlayerWa === void 0 ? void 0 : _clientStoredPlayerWa.WagerListSubscriptionSettings) || {})
    }),
    ResultModifier: resultModifierReducer,
    SportEvents: sportEventReducer,
    Sports: sportReducer,
    Wallet: walletReducer
  };
  const reducerManager = createReducerManager(_objectSpread(_objectSpread({}, getReducers(preloadedCoupons)), baseReducers));
  const mwManager = createMiddlewareManager();
  const storePreloadState = _objectSpread({
    CouponSettings: {
      Tipsinfo: preloadedTipsinfo,
      CompressedCoupon: preloadedCompressedCoupon
    }
  }, preloadedState);
  const drawsApi = {};
  const store = configureStore({
    reducer: reducerManager.reducer,
    preloadedState: storePreloadState,
    middleware: getDefaultMiddleware => [...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: {
        extraArgument: {
          tipsenApi,
          drawsApi
        }
      }
    }), tipsenApi.middleware, loadingStateMiddleware, generateParticipantsMw([{
      action: matchInfoActions.updateMatchOdds,
      getMatchIdFn: action => action.payload.matchId
    }, {
      action: matchInfoActions.fetchMatchInfo.fulfilled,
      getMatchIdFn: action => action.meta.arg
    }]), actionEnhancerMw,
    kibanaMw, localStorageMw, trackingMw, dialogMw, ...competitionMiddlewares,
    mwManager.enhancer],
    devTools: isExtendedReduxDevTools ? {
      maxAge: 250,
      trace: true,
      actionsDenylist: ['TipsenAPI/executeQuery/rejected'],
      shouldCatchErrors: true
    } : true
  });
  Object.assign(drawsApi, makeDrawsApi(store));
  store.reducerManager = reducerManager;
  store.mwManager = mwManager;
  setupListeners(store.dispatch);
  return store;
};
setGlobal('svs.components.tipsen.store.makeStore', makeStore);

 })(window);